const ipRegex =
	/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const isIpValid = (ip) => {
	return ipRegex.test(ip) === true;
};

export const getUserIp = async () => {
	try {
		const resPromise = await fetch('https://api.ipify.org?format=json');
		const res = await resPromise.json();
		if (!isIpValid(res.ip ?? '0.0.0.0')) {
			throw new Error('Invalid IP');
		}
		return res.ip;
	} catch (error) {
		return '0.0.0.0';
	}
};
