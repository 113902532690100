/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { api, pages } from './apiConfig';

export const addWidget = async (
	setSiteId,
	user,
	widgetSettings,
	activeWidgetSettings,
	domain,
	terms,
	email,
	isGradient,
	widgetColor,
	widgetColorGradient,
	setDisableAddButton,
	setIsLoadingButton,
	resources,
	navigate
) => {
	setDisableAddButton(true);
	setIsLoadingButton(true);

	const existingResource = resources.find(
		(resource) => resource.resource_domain_name === domain || `${resource.resource_domain_name}/` === domain
	);

	if (existingResource) {
		setDisableAddButton(false);
		setIsLoadingButton(false);
		toast.error('Site is already exist!');
		return;
	}

	const config = {
		widgetPosition: activeWidgetSettings.hasOwnProperty('bottom')
			? 'bottom'
			: activeWidgetSettings.hasOwnProperty('left')
			? 'left'
			: 'right',
		iconPosition: widgetSettings.hasOwnProperty('right') ? 'rightBottom' : 'leftBottom',
		iconStyle: /px/.test(widgetSettings['borderRadius']) ? 'square' : 'circle',
		termsLink: terms,
		supportEmail: `mailto:${email}`,
		iconPadding: widgetSettings.hasOwnProperty('right') ? widgetSettings['right'] : widgetSettings['left'],
		iconSize: widgetSettings['width'],
		iconColor: isGradient ? `linear-gradient(146.07deg, ${widgetColor} 8.72%, ${widgetColorGradient} 95.56%)` : widgetColor,
	};

	const date = new Date().toISOString();

	const response = await fetch(`${api.url}/${api.widget}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		body: JSON.stringify({ instance_created_at: date, instance_config: JSON.stringify(config) }),
	});

	const data = await response.json();

	if (response.status > 301 && response.status < 599) {
		setDisableAddButton(false);
		setIsLoadingButton(false);
		toast.error('Failed to create!');
		return;
	}

	if (!/^https:\/\//.test(domain)) {
		domain = 'https://' + domain;
	}

	if (!/^https:\/\//.test(terms)) {
		terms = 'https://' + terms;
	}

	const resourceConfig = {
		user_id: user.user_id,
		instance_id: data.instance_id,
		resource_domain_name: domain,
		resource_support_email: email,
		resource_terms_link: terms,
	};

	const resourceResponse = await fetch(`${api.url}/${api.newResource}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		body: JSON.stringify(resourceConfig),
	});

	if (resourceResponse.status > 301 && resourceResponse.status < 599) {
		setDisableAddButton(false);
		setIsLoadingButton(false);
		toast.error('Failed to create!');
		return;
	}

	const endData = await resourceResponse.json();
	setSiteId(endData.resource_id);
	setIsLoadingButton(false);
	toast.success('Site added successfully! Copy the code snippet to add the widget to your site!');

	navigate(pages.editsite.replace(':id', endData.resource_id));
};

export const updateWidget = async (
	user,
	site,
	widgetSettings,
	activeWidgetSettings,
	domain,
	terms,
	email,
	isGradient,
	widgetColor,
	widgetColorGradient,
	setIsLoadingButton
) => {
	setIsLoadingButton(true);
	const config = {
		widgetPosition: activeWidgetSettings.hasOwnProperty('bottom')
			? 'bottom'
			: activeWidgetSettings.hasOwnProperty('left')
			? 'left'
			: 'right',
		iconPosition: widgetSettings.hasOwnProperty('right') ? 'rightBottom' : 'leftBottom',
		iconStyle: /px/.test(widgetSettings['borderRadius']) ? 'square' : 'circle',
		termsLink: terms,
		supportEmail: `mailto:${email}`,
		iconPadding: widgetSettings.hasOwnProperty('right') ? widgetSettings['right'] : widgetSettings['left'],
		iconSize: widgetSettings['width'],
		iconColor: isGradient ? `linear-gradient(146.07deg, ${widgetColor} 8.72%, ${widgetColorGradient} 95.56%)` : widgetColor,
	};

	const response = await fetch(`${api.url}/${api.widgetUpdate}/${site.instance.instance_id}`, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		body: JSON.stringify({ instance_config: JSON.stringify(config) }),
	});

	if (response.status > 301 && response.status < 599) {
		toast.error('Failed to update!');
		setIsLoadingButton(false);
		return;
	}

	const data = await response.json();

	if (!/^https:\/\//.test(domain)) {
		domain = 'https://' + domain;
	}

	if (!/^https:\/\//.test(terms)) {
		terms = 'https://' + terms;
	}

	const resourceConfig = {
		user_id: user.user_id,
		instance_id: data.instance_id,
		resource_domain_name: domain,
		resource_support_email: email,
		resource_terms_link: terms,
	};

	const resourceResponse = await fetch(`${api.url}/${api.updateResource}/${site.resource_id}`, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		body: JSON.stringify(resourceConfig),
	});

	if (resourceResponse.status > 301 && resourceResponse.status < 599) {
		toast.error('Failed to update!');
		setIsLoadingButton(false);
		return;
	}
	setIsLoadingButton(false);
	toast.success('Site edited successfully! Copy the code snippet to add the widget to your site!');
};
