/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetPassword } from '../factory/useData';
import widgetImg from '../img/png/widget.png';
import Footer from './Footer';
import Loading from './Loading';
import Navbar from './Navbar';
import { pages } from '../factory/apiConfig';

const ResetPassword = () => {
	const [passwordRecovery, setPasswordRecovery] = useState(false);
	const [email, setEmail] = useState('');

	const onSuccess = (data) => {
		if (data) {
			setPasswordRecovery(true);
			toast.success(data.access_password);
		}
	};
 
	const onError = () => {
		toast.error('Failed to reset!');
	};

	const { mutate, isLoading } = useResetPassword(onSuccess, onError);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Navbar />
			<main className="main_content">
				<div className="content_block">
					{!passwordRecovery && (
						<div className="left_content">
							<div className="wrapper_sign_block">
								<div className="sign_block">
									<form className="main_form">
										<div className="title_sign mb-25">Reset password</div>
										<div className="text_lg fw-400 mb-25">
											We'll email you instructions to reset your password.
										</div>
										<div className="mb-25">
											<label htmlFor="exEmail" className="form-label">
												Email
											</label>
											<input
												type="email"
												className="form-control"
												id="exEmail"
												placeholder="test@test.com"
												onChange={(e) => setEmail(e.target.value)}
											/>
											<div className="invalid-feedback">Wrong email</div>
										</div>
										<div className="mb-15">
											<a
												className="btn btn_blue btn_big w-100"
												onClick={() => {
													mutate({ user_email: email });
												}}>
												Reset
											</a>
										</div>
										<div className="text_mob_center">
											<div className="">
												<Link to={pages.login} className="text_lg color_blue">
													Return to login
												</Link>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					)}
					{passwordRecovery && (
						<div className="left_content">
							<div className="wrapper_sign_block">
								<div className="sign_block sign_block_dop">
									<form className="main_form">
										<div className="title_sign mb-15">
											Password recovery <br />
											instruction has been sent
										</div>
										<div className="text_lg fw-400 lh-16 mb-25">
											If you don’t receive the instruction to {email} this means you don’t have an account
											yet.
										</div>
										<div className="mb-15">
											<Link to={pages.login} className="btn btn_blue btn_big w-100">
												Back to login
											</Link>
										</div>
									</form>
								</div>
							</div>
						</div>
					)}
					<div className="right_content">
						<div className="wrapper_widget" style={{ paddingRight: '0' }}>
							<img className="img-fluid" src={widgetImg} alt="widget" />
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
};
export default ResetPassword;
