/* eslint-disable jsx-a11y/alt-text */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pages } from '../../factory/apiConfig';
import { useLoginTokenUser } from '../../factory/useData';
import mainPhoto from '../../img/png/Photo_wBadge.png';
import { login } from '../../reducer';

const WelcomeContent = () => {
	const isAuth = useSelector((state) => state.reducer.isAuth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSuccess = (data) => {
		if (data && data.access_token) {
			localStorage.setItem('token', data.access_token);
			dispatch(login());
			navigate(pages.sites);
			toast.success('Successfully login!');
		}
	};

	const onError = () => {
		navigate(pages.login);
		toast.warn('Please login!');
	};

	const { mutate } = useLoginTokenUser(onSuccess, onError);

	return (
		<section className="section_top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="wrapper_top_text">
							<h1 className="title">Free accessibility widget for ADA & WCAG compliance</h1>
							<ul className="type_check">
								<li>100% Free — no credit card required</li>
								<li>Simple installation in seconds</li>
								<li>Covers most of the disabilities</li>
							</ul>
							<div className="wrapper_btns">
								<span
									type="button"
									className="btn btn_blue_border btn_big"
									onClick={() => {
										if (isAuth) {
											navigate(pages.sites);
										} else if (localStorage.getItem('token')) {
											mutate(localStorage.getItem('token'));
										} else {
											navigate(pages.login);
										}
									}}>
									Try it free
								</span>
								<a href="#work_section" type="button" className="btn btn_white btn_big">
									See how it works
								</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="wrapper_top_img">
							<img className="img-fluid" src={mainPhoto} align="Photo wBadge" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default WelcomeContent;
