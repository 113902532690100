/* eslint-disable jsx-a11y/anchor-is-valid */
import arrowToTop from '../img/svg/arrow_to.svg';

const BackToTop = () => {
	return (
		<a href="#" className="back-to-top">
			<img className="navigate" src={arrowToTop} alt="arrow-to" />
		</a>
	);
};
export default BackToTop;
