/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLoginUser } from '../factory/useData';
import computerImg from '../img/png/computer.png';
import widgetImg from '../img/png/widget.png';
import { login } from '../reducer';
import Footer from './Footer';
import Loading from './Loading';
import Navbar from './Navbar';
import { pages } from '../factory/apiConfig';

const inputTypes = {
	show: 'text',
	hide: 'password',
};

const Login = () => {
	const [isPasswordHide, setIsPasswordHide] = useState(inputTypes.hide);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isPasswordValid, setIsPasswordValid] = useState(true);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSuccess = (data) => {
		if (data && data.access_token) {
			localStorage.setItem('token', data.access_token);
			dispatch(login());
			navigate(pages.sites);
			setIsEmailValid(true);
			setIsPasswordValid(true);
			setEmail('');
			setPassword('');
			toast.success('Successfully login!');
		}
	};

	const onError = (data) => {
		setIsEmailValid(false);
		setIsPasswordValid(false);
		setEmail('');
		setPassword('');
		toast.error('Failed to login! Please check your credentials!');
	};

	const { mutate, isLoading } = useLoginUser(onSuccess, onError);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Navbar />
			<main className="main_content">
				<div className="content_block h-100">
					<div className="left_content left_content_dop">
						<div className="wrapper_sign_block">
							<div className="sign_block pt-5">
								<div className="text-center d-sm-none h-half">
									<img className="img-fluid mb-15" src={computerImg} alt="computer" />
									<div className="text fw-800">
										Please use your desktop <br />
										version to login
									</div>
								</div>
								<form className="main_form main_form_mob">
									<div className="title_sign mb-20">Login</div>
									<div className="mb-25">
										<label htmlFor="exEmail" className="form-label">
											Email
										</label>
										<input
											type="email"
											className={`form-control ${isEmailValid ? '' : 'is-invalid'}`}
											id="exEmail"
											placeholder="example@email.com"
											onChange={(e) => setEmail(e.target.value)}
										/>
										{isPasswordValid && <div className="invalid-feedback">Wrong email</div>}
									</div>
									<div className="mb-30">
										<div className="d-flex justify-content-between">
											<label htmlFor="exPassword" className="form-label">
												Password
											</label>
											<div
												className="text_lg cursor_pointer fw-400 color_blue"
												onClick={() => {
													setIsPasswordHide(
														isPasswordHide === inputTypes.hide ? inputTypes.show : inputTypes.hide
													);
												}}>
												Show
											</div>
										</div>
										<input
											type={isPasswordHide}
											className={`form-control ${isPasswordValid ? '' : 'is-invalid'}`}
											id="exPassword"
											placeholder="********"
											onChange={(e) => setPassword(e.target.value)}
										/>
										<div className="invalid-feedback">Wrong email or password</div>
									</div>
									<div className="mb-15">
										<button
											type="button"
											className="btn btn_blue btn_big w-100"
											onClick={async () => {
												mutate({ user_email: email, user_password: password });
											}}>
											Login
										</button>
									</div>
									<div className="text_mob_center">
										<div className="mb-10">
											<Link to={pages.reset} className="text_lg color_blue">
												Forgot a password?
											</Link>
										</div>
										<div>
											<Link to={pages.register} className="text_lg color_blue">
												Don’t have an account?
											</Link>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="right_content">
						<div className="wrapper_widget" style={{ paddingRight: '0' }}>
							<img className="img-fluid" src={widgetImg} alt="widget" />
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
};
export default Login;
