/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pages } from "../factory/apiConfig";
import { useRegisterUser } from "../factory/useData";
import widgetImg from "../img/png/widget.png";
import { login } from "../reducer";
import Footer from "./Footer";
import Loading from "./Loading";
import Navbar from "./Navbar";
import { getUserIp } from "../factory/getUserIp";
const inputTypes = {
  show: "text",
  hide: "password",
};

const Register = () => {
  const [isPasswordHide, setIsPasswordHide] = useState(inputTypes.hide);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [lowerCh, setLowerCh] = useState(false);
  const [upperCh, setUpperCh] = useState(false);
  const [numberCh, setNumberCh] = useState(false);
  const [specialCh, setSpecialCh] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSuccess = (data) => {
    if (data && data.access_token) {
      localStorage.setItem("token", data.access_token);
      dispatch(login());
      navigate(pages.sites);

      setIsEmailValid(true);
      setIsPasswordValid(true);

      setEmail("");
      setPassword("");
      setName("");
      setLowerCh(false);
      setUpperCh(false);
      setNumberCh(false);
      setSpecialCh(false);
      setPasswordLength(false);
      toast.success("Successfully registered!");
    }
  };

  const onError = () => {
    setIsEmailValid(false);
    setEmail("");
    setPassword("");
    setName("");
    setLowerCh(false);
    setUpperCh(false);
    setNumberCh(false);
    setSpecialCh(false);
    setPasswordLength(false);
    toast.error("Failed to register! Please, check your credentials!");
  };

  const { mutate, isLoading } = useRegisterUser(onSuccess, onError);

  const checkPassword = (passwordValidate) => {
    const tests = [
      { regex: /^(?=.*[a-z])/, stateUpdater: setLowerCh },
      { regex: /^(?=.*[A-Z])/, stateUpdater: setUpperCh },
      { regex: /^(?=.*\d)/, stateUpdater: setNumberCh },
      { regex: /^(?=.*[@$!%*?&#])/, stateUpdater: setSpecialCh },
      { regex: /^\S{8,}$/, stateUpdater: setPasswordLength },
    ];

    let isValid = true;

    tests.forEach(({ regex, stateUpdater }) => {
      const testResult = regex.test(passwordValidate);
      stateUpdater(testResult);

      if (!testResult) {
        isValid = false;
        setIsPasswordValid(isValid);
      }
    });

    setPassword((prev) => passwordValidate);
    if (isValid) {
      setIsPasswordValid(isValid);
      setPassword((prev) => passwordValidate);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <main className="main_content">
        <div className="content_block">
          <div className="left_content">
            <div className="wrapper_sign_block">
              <div className="sign_block">
                <form className="main_form">
                  <div className="title_sign mb-15">Sign up</div>
                  <div className="text_lg fw-500 mb-25">
                    No credit card required
                  </div>
                  <div className="mb-15">
                    <label htmlFor="exName" className="form-label">
                      Name
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                      id="exName"
                      placeholder="Rick"
                      required
                    />
                  </div>
                  <div className="mb-15">
                    <label htmlFor="exEmail" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        !email || isEmailValid ? "" : "is-invalid"
                      }`}
                      id="exEmail"
                      placeholder="example@email.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsEmailValid(e.target.validity.valid);
                      }}
                      required
                    />

                    <div className="invalid-feedback">
                      This email already used
                    </div>
                  </div>
                  <div className="mb-25">
                    <div className="d-flex justify-content-between">
                      <label htmlFor="exPassword" className="form-label">
                        Password
                      </label>
                      <div
                        className="text_lg cursor_pointer fw-400 color_blue"
                        onClick={() => {
                          setIsPasswordHide(
                            isPasswordHide === inputTypes.hide
                              ? inputTypes.show
                              : inputTypes.hide
                          );
                        }}
                      >
                        Show
                      </div>
                    </div>
                    <input
                      type={isPasswordHide}
                      className={`form-control ${
                        isPasswordValid ? "" : "is-invalid"
                      }`}
                      id="exPassword"
                      placeholder="********"
                      onChange={(e) => checkPassword(e.target.value)}
                      required
                    />
                    {password.length > 0 && (
                      <ul className="ul_pass">
                        <li className={lowerCh ? "li_done" : "li_no"}>
                          <div className="text_sm_dop">
                            One lowercase character
                          </div>
                        </li>
                        <li className={upperCh ? "li_done" : "li_no"}>
                          <div className="text_sm_dop">
                            One uppercase character
                          </div>
                        </li>
                        <li className={numberCh ? "li_done" : "li_no"}>
                          <div className="text_sm_dop">One number</div>
                        </li>
                        <li className={specialCh ? "li_done" : "li_no"}>
                          <div className="text_sm_dop">
                            One special character
                          </div>
                        </li>
                        <li className={passwordLength ? "li_done" : "li_no"}>
                          <div className="text_sm_dop">
                            8 characters minimum
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="text_sm_dop mb-15">
                    By creating an account, you agree to our{" "}
                    <a href="#" className="color_blue">
                      Terms
                    </a>{" "}
                    and have read and acknowledge the{" "}
                    <a href="#" className="color_blue">
                      Privacy Statement
                    </a>
                    .
                  </div>
                  <div className="mb-15">
                    <a
                      onClick={async () => {
                        if (
                          isEmailValid &&
                          isPasswordValid &&
                          password &&
                          email &&
                          name
                        ) {
                          const ip = await getUserIp();

                          mutate({
                            user_name: name,
                            user_email: email,
                            user_password: password,
                            registration_ip: ip,
                          });
                        } else {
                          toast.error(
                            "Please, fill all fields with correct data!"
                          );
                        }
                      }}
                      className="btn btn_blue btn_big w-100"
                    >
                      Sign up
                    </a>
                  </div>
                  <div className="text_mob_center">
                    <Link to={pages.login} className="text_lg color_blue">
                      Already have an account?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="right_content">
            <div className="wrapper_widget" style={{ paddingRight: "0" }}>
              <img className="img-fluid" src={widgetImg} alt="widget" />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};
export default Register;
