import { useMutation, useQuery } from "react-query";
import { api } from "./apiConfig";

export const useFetchUserProfile = (token, isAuth) => {
  async function fetchUserProfile(token) {
    const response = await fetch(`${api.url}/${api.user}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error fetching user profile: " + response.statusText);
    }

    return response.json();
  }

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useQuery("userProfile", () => fetchUserProfile(token), {
    enabled: isAuth,
  });

  return [user, isLoadingUser, isErrorUser, errorUser];
};

export const useFetchUserResources = (token, isAuth) => {
  async function fetchUserResources(token) {
    const response = await fetch(`${api.url}/${api.resources}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error fetching user resources: " + response.statusText);
    }

    return response.json();
  }
  const {
    data: resources,
    isLoading: isLoadingResources,
    isError: isErrorResources,
    error: errorResources,
  } = useQuery("userResources", () => fetchUserResources(token), {
    enabled: isAuth,
  });

  return [resources, isLoadingResources, isErrorResources, errorResources];
};

export const useRegisterUser = (onSuccess, onError) => {
  const registerUser = async (credentials) => {
    const response = await fetch(`${api.url}/${api.register}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error("Registration failed");
    }
    if (response.status > 301 && response.status < 599) {
      throw new Error("Registration failed");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(registerUser, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useLoginUser = (onSuccess, onError) => {
  const loginUser = async (credentials) => {
    const response = await fetch(`${api.url}/${api.login}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    if (response.status > 301 && response.status < 599) {
      throw new Error("Login failed");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useLoginTokenUser = (onSuccess, onError) => {
  const loginUser = async (token) => {
    const response = await fetch(`${api.url}/${api.tokenLogin}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    if (response.status > 301 && response.status < 599) {
      throw new Error("Login failed");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useFetchSiteData = (id, onSuccess) => {
  const fetchSiteData = async (id) => {
    const response = await fetch(`${api.url}/${api.resources}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  return useQuery(["siteData", id], () => fetchSiteData(id), {
    enabled: !!id,
    onSuccess,
  });
};

export const useResetPassword = (onSuccess, onError) => {
  const reset = async (credentials) => {
    const response = await fetch(`${api.url}/${api.reset}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error("Reset failed");
    }

    if (response.status > 301 && response.status < 599) {
      throw new Error("Reset failed");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(reset, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useVerifyEmail = (onSuccess, onError) => {
  const verifyEmail = async (token) => {
    const response = await fetch(`${api.url}/${api.verify}/${token}`);
    if (!response.ok) {
      throw new Error("Email verification failed");
    }

    if (response.status > 301 && response.status < 599) {
      throw new Error("Email verification failed");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(verifyEmail, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useUpdateUser = (onSuccess, onError) => {
  const updateUser = async ({ userId, credentials }) => {
    const response = await fetch(`${api.url}/${api.user}/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error("Failed to update");
    }

    if (response.status > 301 && response.status < 599) {
      throw new Error("Failed to update");
    }

    return response.json();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(updateUser, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useFetchUsers = (token, isAuth, id) => {
  async function fetchUserProfile(token, id) {
    if (id) {
      const response = await fetch(`${api.url}/${api.adminUsers}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching user profile: " + response.statusText);
      }

      return response.json();
    }
  }

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUser,
    error: errorUser,
    refetch: refetchUsers,
  } = useQuery("adminUsers", () => fetchUserProfile(token, id), {
    enabled: isAuth,
  });

  return [users, isLoadingUsers, isErrorUser, errorUser, refetchUsers];
};
