import { memo } from 'react';
import { useRoutes } from 'react-router-dom';
import { pages } from '../factory/apiConfig';
import AuthProvider from '../factory/AuthProvider';
import AddWidget from './AddWidget';
import Admin from './Admin';
import EditWidget from './EditWidget';
import ErrorPage from './ErrorPage';
import Login from './Login';
import MainContent from './MainContent';
import ProfilePage from './ProfilePage';
import Register from './Register';
import ResetPassword from './ResetPassword';
import SitesLayout from './SitesLayout';
import VerificationSuccess from './VerificationSuccess';

export const Routes = memo(() => {
	return useRoutes([
		{
			path: pages.main,

			element: (
				<AuthProvider>
					<MainContent />
				</AuthProvider>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: pages.sites,
			element: (
				<AuthProvider>
					<SitesLayout />
				</AuthProvider>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: pages.login,
			element: <Login />,
			errorElement: <ErrorPage />,
		},
		{
			path: pages.register,
			element: <Register />,
			errorElement: <ErrorPage />,
		},

		{
			path: pages.newsite,
			element: (
				<AuthProvider>
					<AddWidget />
				</AuthProvider>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: pages.admin,
			element: <Admin />,
			errorElement: (
				<AuthProvider>
					<ErrorPage />
				</AuthProvider>
			),
		},
		{
			path: pages.editsite,
			element: (
				<AuthProvider>
					<EditWidget />
				</AuthProvider>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: pages.profile,
			element: (
				<AuthProvider>
					<ProfilePage />
				</AuthProvider>
			),
			errorElement: <ErrorPage />,
		},
		{
			path: pages.reset,
			element: <ResetPassword />,
			errorElement: <ErrorPage />,
		},
		{
			path: pages.verify,
			element: <VerificationSuccess />,
			errorElement: <ErrorPage />,
		},

		{ path: '*', element: <ErrorPage /> },
	]);
});
