/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import copy from "copy-to-clipboard";
import $ from "jquery";
import { Copy } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateWidget } from "../factory/createData";
import { useFetchSiteData, useFetchUserProfile } from "../factory/useData";
import Footer from "./Footer";
import Loading from "./Loading";
import Modal from "./Modal";
import Navbar from "./Navbar";
import { pages } from "../factory/apiConfig";

const EditWidget = () => {
  const { id } = useParams();
  //const [condition, setCondition] = useState(true);
  const [domain, setDomain] = useState("");
  const [termsLink, setTermsLink] = useState("");
  const [supportEmail, setSupportEmail] = useState("");

  const [isWidgetActive, setIsWidgetActive] = useState(false);
  const [isVertical, setIsVertical] = useState(false);
  const [isRightBottom, setIsRightBottom] = useState(false);
  const [isRightBottomIcon, setIsRightBottomIcon] = useState(false);
  const [isCircle, setIsCircle] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [isGradient, setIsGradient] = useState(true);
  const [widgetColor, setWidgetColor] = useState("#2F5BDF");
  const [widgetColorGradient, setWidgetColorGradient] = useState("#1A41BA");

  const [widgetSettings, setWidgetSettings] = useState({});
  const [activeWidgetSettings, setActiveWidgetSettings] = useState({});

  const isAuth = useSelector((state) => state.reducer.isAuth);

  const onSuccess = (data) => {
    if (data) {
      setDomain(data.resource_domain_name);
      setTermsLink(data.resource_terms_link);
      setSupportEmail(data.resource_support_email);
      parseWidgetConfig(data.instance.instance_config);
    }
  };

  //   const handleClick = () => {
  //     setCondition(false);
  //   };

  const { data: site, isLoading: isLoadingSite } = useFetchSiteData(
    id,
    onSuccess
  );
  const [user] = useFetchUserProfile(localStorage.getItem("token"), isAuth);

  const navigate = useNavigate();

  const copyToClipboard = () => {
    // copy(
    // 	`<script src="https://${window.location.host}/api" data-site-id="${site.resource_id}" async></script>\n<script src="https://${window.location.host}/api/embedded/widget"></script>`
    // );
    copy(
      `<script src="https://${window.location.host}/api/embedded/widget" data-site-id="${site.resource_id}" async></script>`
    );
    toast.success("Code snippet successfully copied to clipboard!");
  };

  const parseWidgetConfig = (configString) => {
    const {
      iconStyle,
      iconPosition,
      widgetPosition,
      iconSize,
      iconPadding,
      iconColor,
    } = JSON.parse(configString);

    if (iconStyle === "circle") {
      setIsCircle(true);
    }

    if (/deg/.test(iconColor)) {
      setIsGradient(true);
      const colorRegex = /#([a-f\d]{6})/gi;
      const colors = iconColor.match(colorRegex);
      setWidgetColor(colors[0]);
      setWidgetColorGradient(colors[1]);
    } else {
      setIsGradient(false);
      setWidgetColor(iconColor);
    }

    if (iconPosition === "leftBottom") {
      setWidgetSettings({
        width: iconSize,
        height: iconSize,
        bottom: "24px",
        left: iconPadding,
        borderRadius: iconStyle === "circle" ? "50%" : "8px",
      });
    } else {
      setIsRightBottomIcon(true);
      setWidgetSettings({
        width: iconSize,
        height: iconSize,
        bottom: "24px",
        right: iconPadding,
        borderRadius: iconStyle === "circle" ? "50%" : "8px",
      });
    }

    if (widgetPosition === "left") {
      setIsVertical(false);
      setActiveWidgetSettings({
        width: "30%",
        height: "90%",
        left: "24px",
      });
    } else if (widgetPosition === "right") {
      setIsVertical(false);
      setIsRightBottom(true);
      setActiveWidgetSettings({
        width: "30%",
        height: "90%",
        right: "24px",
      });
    } else {
      setActiveWidgetSettings({
        width: "95%",
        height: "20%",
        bottom: "24px",
      });
    }
  };

  const togglePosition = () => {
    document
      .getElementById("right_bottom_position")
      .classList.toggle("selected");
    document
      .getElementById("left_bottom_position")
      .classList.toggle("selected");

    if (widgetSettings.hasOwnProperty("right")) {
      setWidgetSettings({
        width: widgetSettings.width,
        height: widgetSettings.height,
        bottom: widgetSettings.bottom,
        left: "10px",
        borderRadius: widgetSettings.borderRadius,
      });
    } else {
      setWidgetSettings({
        width: widgetSettings.width,
        height: widgetSettings.height,
        bottom: widgetSettings.bottom,
        right: "10px",
        borderRadius: widgetSettings.borderRadius,
      });
    }
  };

  const toggleStyle = () => {
    document.getElementById("style_square").classList.toggle("selected");
    document.getElementById("style_round").classList.toggle("selected");

    const newBorderRadius = widgetSettings.borderRadius.includes("px")
      ? "50%"
      : "8px";

    setWidgetSettings({
      ...widgetSettings,
      borderRadius: newBorderRadius,
    });
  };

  useEffect(() => {
    if (!isAuth) {
      navigate(pages.login);
    }
  }, [isAuth]);

  if (isLoadingSite) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="navigation_wrapper">
          <Link className="hover_text" to={pages.sites}>
            Webresources
          </Link>
          <span>{">"}</span>
          <Link
            className="hover_text"
            to={pages.editsite.replace(":id", site.resource_id)}
          >
            Adjust website
          </Link>
        </div>
      </div>

      <main className="main_content">
        <div className="content_block">
          <div className="left_content">
            <div className="wrapper_sign_block">
              <div className="sign_block sign_block_big">
                <form className="main_form">
                  <div className="title_sign mb-20">Adjust website</div>
                  <div className="text_lg fw-800 mb-15">Website</div>
                  <div className="mb-15">
                    <label htmlFor="exDomainname" className="form-label">
                      Domain name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exDomainname"
                      placeholder=""
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                  </div>
                  <div className="mb-15">
                    <label htmlFor="exSupportemail" className="form-label">
                      Support email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exSupportemail"
                      placeholder=""
                      value={supportEmail}
                      onChange={(e) => setSupportEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-30">
                    <label htmlFor="exTermslink" className="form-label">
                      Terms link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exTermslink"
                      placeholder=""
                      value={termsLink}
                      onChange={(e) => setTermsLink(e.target.value)}
                    />
                  </div>
                  <div className="text_lg fw-800 mb-15">Icon design</div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <div className="text_lg fw-400">Position</div>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block">
                        <div className="item_select_block">
                          <div
                            id="right_bottom_position"
                            className={`select_block ${
                              isRightBottomIcon ? "selected" : ""
                            }`}
                            onClick={togglePosition}
                          >
                            Right bottom
                          </div>
                        </div>
                        <div className="item_select_block">
                          <div
                            id="left_bottom_position"
                            className={`select_block ${
                              isRightBottomIcon ? "" : "selected"
                            }`}
                            onClick={togglePosition}
                          >
                            Left bottom
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <div className="text_lg fw-400">Style</div>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block">
                        <div className="item_select_block">
                          <div
                            id="style_square"
                            className={`select_block ${
                              isCircle ? "" : "selected"
                            }`}
                            onClick={toggleStyle}
                          >
                            Square
                          </div>
                        </div>
                        <div className="item_select_block">
                          <div
                            id="style_round"
                            className={`select_block ${
                              isCircle ? "selected" : ""
                            }`}
                            onClick={toggleStyle}
                          >
                            Round
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <div className="text_lg fw-400">Color style</div>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block">
                        <div className="item_select_block">
                          <div
                            id="style_square"
                            className={`select_block ${
                              isGradient ? "selected" : ""
                            }`}
                            onClick={() => setIsGradient(!isGradient)}
                          >
                            Gradient
                          </div>
                        </div>
                        <div className="item_select_block">
                          <div
                            id="style_round"
                            className={`select_block ${
                              isGradient ? "" : "selected"
                            }`}
                            onClick={() => setIsGradient(!isGradient)}
                          >
                            Single color
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <label htmlFor="exColor" className="text_lg fw-400">
                        Color
                      </label>
                    </div>
                    {isGradient ? (
                      <div className="col-md-8 col-dop">
                        <div className="color_picker_wrapper">
                          <input
                            type="text"
                            className="color_text"
                            id="exColor"
                            value={widgetColor}
                            onChange={(e) => setWidgetColor(e.target.value)}
                          />
                          <input
                            type="color"
                            value={widgetColor}
                            className="color_picker"
                            id="exxColor"
                            onChange={(e) => setWidgetColor(e.target.value)}
                          />
                        </div>
                        <div
                          className="color_picker_wrapper"
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            type="text"
                            className="color_text"
                            id="exColor"
                            value={widgetColorGradient}
                            onChange={(e) =>
                              setWidgetColorGradient(e.target.value)
                            }
                          />
                          <input
                            type="color"
                            value={widgetColorGradient}
                            className="color_picker"
                            id="exxColor"
                            onChange={(e) =>
                              setWidgetColorGradient(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-8 col-dop">
                        <div className="color_picker_wrapper">
                          <input
                            type="text"
                            className="color_text"
                            id="exColor"
                            value={widgetColor}
                            onChange={(e) => setWidgetColor(e.target.value)}
                          />
                          <input
                            type="color"
                            value={widgetColor}
                            className="color_picker"
                            id="exxColor"
                            onChange={(e) => setWidgetColor(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <label htmlFor="exSize" className="text_lg fw-400">
                        Size
                      </label>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block align-items-center">
                        <input
                          type="text"
                          className="form-control mw-203"
                          id="exSize"
                          placeholder="50"
                          onChange={(e) =>
                            setWidgetSettings({
                              ...widgetSettings,
                              width: e.target.value + "px",
                              height: e.target.value + "px",
                            })
                          }
                        />
                        <div className="text_lg fw-400 pl-15">px</div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <label
                        htmlFor="exPaddingbottom"
                        className="text_lg fw-400"
                      >
                        Padding bottom
                      </label>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block align-items-center">
                        <input
                          type="text"
                          className="form-control mw-203"
                          id="exPaddingbottom"
                          placeholder="30"
                          onChange={(e) =>
                            setWidgetSettings({
                              ...widgetSettings,
                              bottom: e.target.value + "px",
                            })
                          }
                        />
                        <div className="text_lg fw-400 pl-15">px</div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-30">
                    <div className="col-md-4 col-dop">
                      <label
                        htmlFor="exPaddingright"
                        className="text_lg fw-400"
                      >
                        Padding right
                      </label>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block align-items-center">
                        <input
                          disabled={!widgetSettings.hasOwnProperty("right")}
                          type="text"
                          className="form-control mw-203"
                          id="exPaddingright"
                          placeholder="60"
                          onChange={(e) =>
                            setWidgetSettings({
                              ...widgetSettings,
                              right: e.target.value + "px",
                            })
                          }
                        />
                        <div className="text_lg fw-400 pl-15">px</div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-30">
                    <div className="col-md-4 col-dop">
                      <label
                        htmlFor="exPaddingright"
                        className="text_lg fw-400"
                      >
                        Padding left
                      </label>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block align-items-center">
                        <input
                          disabled={widgetSettings.hasOwnProperty("right")}
                          type="text"
                          className="form-control mw-203"
                          id="exPaddingright"
                          placeholder="60"
                          onChange={(e) =>
                            setWidgetSettings({
                              ...widgetSettings,
                              left: e.target.value + "px",
                            })
                          }
                        />
                        <div className="text_lg fw-400 pl-15">px</div>
                      </div>
                    </div>
                  </div>
                  <div className="text_lg fw-800 mb-15">Widget design</div>
                  <div className="row row-dop align-items-center mb-25">
                    <div className="col-md-4 col-dop">
                      <div className="text_lg fw-400">Layout</div>
                    </div>
                    <div className="col-md-8 col-dop">
                      <div className="wrapper_select_block">
                        <div className="item_select_block">
                          <div
                            className={`select_block ${
                              isVertical ? "selected" : ""
                            }`}
                            onClick={() => {
                              setIsVertical(true);
                              setIsRightBottom(false);
                              setActiveWidgetSettings({
                                left: "10px",
                                width: "30%",
                                height: "90%",
                              });
                            }}
                          >
                            Vertical
                          </div>
                        </div>
                        <div className="item_select_block">
                          <div
                            className={`select_block ${
                              isVertical ? "" : "selected"
                            }`}
                            onClick={() => {
                              setIsVertical(false);
                              setActiveWidgetSettings({
                                bottom: "10px",
                                width: "95%",
                                height: "20%",
                              });
                            }}
                          >
                            Horizontal
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-dop align-items-center mb-40">
                    <div className="col-md-4 col-dop">
                      <div className="text_lg fw-400">Position</div>
                    </div>
                    {!isVertical ? (
                      <div className="col-md-8 col-dop">
                        <div className="wrapper_select_block">
                          <div className="item_select_block">
                            <div className="select_block selected">Bottom</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-8 col-dop">
                        <div className="wrapper_select_block">
                          <div className="item_select_block">
                            <div
                              id="right_style"
                              className={`select_block ${
                                isRightBottom ? "" : "selected"
                              }`}
                              onClick={() => {
                                setIsRightBottom(false);
                                setActiveWidgetSettings({
                                  left: "10px",
                                  width: "30%",
                                  height: "90%",
                                });
                              }}
                            >
                              Left
                            </div>
                          </div>
                          <div className="item_select_block">
                            <div
                              id="left_style"
                              className={`select_block ${
                                isRightBottom ? "selected" : ""
                              }`}
                              onClick={() => {
                                setIsRightBottom(true);
                                setActiveWidgetSettings({
                                  right: "10px",
                                  width: "30%",
                                  height: "90%",
                                });
                              }}
                            >
                              Right
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-15">
                    {isLoadingButton ? (
                      <div className="btn btn_big w-100">
                        <div class="center">
                          <div class="blue"></div>
                          <div class="blue"></div>
                          <div class="blue"></div>
                        </div>
                      </div>
                    ) : (
                      <a
                        className="btn btn_blue btn_big w-100"
                        onClick={() => {
                          if (domain && termsLink && supportEmail) {
                            updateWidget(
                              user,
                              site,
                              widgetSettings,
                              activeWidgetSettings,
                              domain,
                              termsLink,
                              supportEmail,
                              isGradient,
                              widgetColor,
                              widgetColorGradient,
                              setIsLoadingButton
                            );
                          }
                        }}
                      >
                        Save
                      </a>
                    )}
                  </div>
                  <div
                    className="text-center"
                    onClick={() => {
                      $(".modal").addClass("show");
                      $("body").addClass("modal-open");
                    }}
                  >
                    <Link className="text_lg fw-700 color_red">
                      Remove widget
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="right_content right_content_dop">
            <div className="wrapper_widget_preview">
              <div className="site">
                <div className="site_header">
                  <div className="site_url">
                    {domain ? (
                      <span className="site_domain">{domain}</span>
                    ) : (
                      <span className="site_domain">
                        https://www.domain.name.com
                      </span>
                    )}
                  </div>
                </div>
                <div className="site_main-content">
                  <div className="site_fake-content">
                    <p className="prev_code_snippet1">
                      Copy this script below, and paste it in the end your html
                      body
                    </p>
                    <div className="fake_main-block">
                      {site && (
                        <div className="code_snippet_wrapper">
                          <div
                            className="code_snippet_copy"
                            onClick={() => copyToClipboard()}
                          >
                            <Copy />
                          </div>
                          <p className="code_snippet_text">
                            <span className="code_snippet_tag">
                              {"<script"}
                            </span>
                            <span className="code_snippet_attributes">
                              {" "}
                              src="
                            </span>
                            <span className="code_snippet_data">{`https://${window.location.host}/api/embedded/widget`}</span>
                            <span className="code_snippet_attributes">
                              " data-site-id="
                            </span>
                            <span className="code_snippet_data">{`${site.resource_id}`}</span>
                            <span className="code_snippet_attributes">
                              " async
                            </span>
                            <span className="code_snippet_tag">
                              {"></script>"}
                            </span>
                          </p>
                          {/* <p className="code_snippet_text">
													<span className="code_snippet_tag">{'<script'}</span>
													<span className="code_snippet_attributes"> src</span>
													<span className="code_snippet_attributes">="</span>
													<span className="code_snippet_data">{`https://${window.location.host}/api/embedded/widget`}</span>
													<span className="code_snippet_attributes">" </span>

													<span className="code_snippet_tag">{'></script>'}</span>
												</p> */}
                        </div>
                      )}
                    </div>
                    <div className="long_fake-block"></div>
                    <div className="fake_blocks"></div>
                    <div className="fake_blocks"></div>
                    <div className="fake_blocks"></div>
                    <div className="fake_blocks"></div>
                  </div>
                  {/* {condition && (
                    <div className="modal_window">
                      <div onClick={handleClick} className="icon">
                        <Close11 />
                      </div>
                      <p className="prev_code_snippet1">
                        Copy this script below, and paste it in the end your
                        html body
                      </p>
                      <div className="code_snippet_wrapper1">
                        <div
                          className="code_snippet_copy"
                          onClick={() => copyToClipboard()}
                        >
                          <Copy />
                        </div>
                        <p className="code_snippet_text">
                          <span className="code_snippet_tag">{"<script"}</span>
                          <span className="code_snippet_attributes">
                            {" "}
                            src="
                          </span>
                          <span className="code_snippet_data">{`https://${window.location.host}/api/embedded/widget`}</span>
                          <span className="code_snippet_attributes">
                            " data-site-id="
                          </span>
                          <span className="code_snippet_data">{`${site.resource_id}`}</span>
                          <span className="code_snippet_attributes">
                            " async
                          </span>
                          <span className="code_snippet_tag">
                            {"></script>"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )} */}

                  {isWidgetActive ? (
                    <div
                      className="active_widget"
                      style={activeWidgetSettings}
                      onClick={() => setIsWidgetActive(false)}
                    ></div>
                  ) : (
                    <svg
                      onClick={() => setIsWidgetActive(true)}
                      className="site_widget-preview"
                      style={widgetSettings}
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      xmlns="http://www.w3.org/2000/svg"
                      fill={
                        isGradient ? "url(#paint0_linear_0_1)" : widgetColor
                      }
                    >
                      <rect
                        x="2"
                        y="2"
                        rx={widgetSettings.borderRadius}
                        width="44"
                        height="44"
                        stroke="white"
                        strokeOpacity="0.9"
                        strokeWidth="4"
                      />
                      <path
                        d="M24 15.5625C25.8105 15.5625 27.2812 14.0918 27.2812 12.2812C27.2812 10.4707 25.8105 9 24 9C22.1895 9 20.7188 10.4707 20.7188 12.2812C20.7188 14.0918 22.1895 15.5625 24 15.5625Z"
                        fill="white"
                        fillOpacity="0.9"
                      />
                      <path
                        d="M34.3125 15.6094L34.2832 15.6152L34.2598 15.6211C34.2012 15.6387 34.1426 15.6562 34.084 15.6738C32.9941 15.9961 27.7031 17.4844 23.9707 17.4844C20.5078 17.4844 15.6914 16.1953 14.1504 15.7559C13.998 15.6973 13.8398 15.6445 13.6816 15.6035C12.5684 15.3105 11.8066 16.4414 11.8066 17.4727C11.8066 18.498 12.7266 18.9844 13.6523 19.3359V19.3535L19.2305 21.0937C19.7988 21.3105 19.9512 21.5332 20.0273 21.7266C20.2676 22.3477 20.0742 23.5781 20.0098 24.0059L19.6699 26.6426L17.7891 36.9727C17.7832 37.002 17.7773 37.0312 17.7715 37.0605L17.7598 37.1367C17.625 38.0801 18.3164 39 19.6348 39C20.7832 39 21.293 38.209 21.5098 37.1309C21.5098 37.1309 23.1504 27.8965 23.9707 27.8965C24.791 27.8965 26.4785 37.1309 26.4785 37.1309C26.7012 38.209 27.2051 39 28.3535 39C29.6719 39 30.3691 38.0801 30.2285 37.1309C30.2168 37.0488 30.1992 36.9727 30.1816 36.8906L28.2773 26.6543L27.9375 24.0176C27.6914 22.4824 27.8906 21.9727 27.9551 21.8555C27.9551 21.8555 27.9609 21.8496 27.9609 21.8437C28.0254 21.7266 28.3125 21.4629 28.9863 21.2109L34.2188 19.3828C34.248 19.377 34.2832 19.3652 34.3125 19.3535C35.25 19.002 36.1875 18.5156 36.1875 17.4844C36.1875 16.4531 35.4258 15.3164 34.3125 15.6094Z"
                        fill="white"
                        fillOpacity="0.9"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_0_1"
                          x1="10.4118"
                          y1="-7.26741e-07"
                          x2="42.7059"
                          y2="48"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor={widgetColor} />
                          <stop offset="1" stopColor={widgetColorGradient} />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />

      <Modal domain={domain} id={id} instance_id={site.instance.instance_id} />
    </>
  );
};
export default EditWidget;

export const Close11 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-x"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
};
