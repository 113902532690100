/* eslint-disable no-unused-vars */
import { useState } from 'react';
import closeMenu from '../../img/svg/Close_Menu.svg';

const AccessibilityAdjustments = ({ openSidebar, handleCloseMenu }) => {
	const [uncoloredDisplay, setUncoloredDisplay] = useState(false);
	const [brightContrast, setBrightContrast] = useState(false);
	const [reverseContrast, setReverseContrast] = useState(false);
	const [increaseText, setIncreaseText] = useState(false);
	const [decreaseText, setDecreaseText] = useState(false);
	const [fontFamily, setFontFamily] = useState(false);
	const [underlineAllLinks, setUnderlineAllLinks] = useState(false);
	const [underlineAllHeaders, setUnderlineAllHeaders] = useState(false);
	const [imageAltText, setImageAltText] = useState(false);
	const [bigCursor, setBigCursor] = useState(false);
	const [bigBlackCursor, setBigBlackCursor] = useState(false);
	const [zoomUp, setZoomUp] = useState(false);
	const [keyboard, setKeyboard] = useState(false);
	const [animations, setAnimations] = useState(false);
	let altTextVisible = true;

	const [fontValue, setFontValue] = useState(0);
	const [fontSizePercent, setFontSizePercent] = useState(0);

	function resetSettings() {
		if (uncoloredDisplay) {
			toggleUncoloredDisplay();
		}
		if (brightContrast) {
			toggleBrightContrast();
		}
		if (reverseContrast) {
			toggleReverseContrast();
		}
		if (fontFamily) {
			toggleFontFamily();
		}
		if (underlineAllLinks) {
			toggleUnderlineAllLinks();
		}
		if (underlineAllHeaders) {
			toggleUnderlineAllHeaders();
		}
		if (altTextVisible) {
			toggleImageAltText();
		}
		if (bigCursor) {
			toggleBigCursor();
		}
		if (bigBlackCursor) {
			toggleBigBlackCursor();
		}
		if (zoomUp) {
			toggleZoomUp();
		}
		if (keyboard) {
			toggleKbd();
		}
		if (animations) {
			toggleAnimations();
		}
		if (increaseText) {
			for (let i = fontValue; 0 < i; i--) {
				toggleDecreaseText();
			}
			setIncreaseText(!increaseText);
			setFontSizePercent(0);
			setFontValue(0);
		}
		if (decreaseText) {
			for (let i = fontValue; 0 > i; i++) {
				toggleIncreaseText();
			}
			setDecreaseText(!decreaseText);
			setFontSizePercent(0);
			setFontValue(0);
		}
	}

	const toggleUncoloredDisplay = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-contrast-monochrome');
		setUncoloredDisplay(!uncoloredDisplay);
	};

	const toggleBrightContrast = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-contrast-soft');
		setBrightContrast(!brightContrast);
	};

	const toggleReverseContrast = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-contrast-hard');
		setReverseContrast(!reverseContrast);
	};

	const toggleIncreaseText = () => {
		const textElements = document.querySelectorAll('*');
		textElements.forEach((element) => {
			const computedStyle = getComputedStyle(element);
			const currentFontSize = computedStyle.fontSize;
			const currentFontSizeValue = parseFloat(currentFontSize);

			if (!isNaN(currentFontSizeValue)) {
				if (fontValue < 3) {
					setFontValue(fontValue + 1);
					const newFontSize = currentFontSizeValue + 4;
					setFontSizePercent(fontSizePercent + 27);
					element.style.fontSize = newFontSize + 'px';
					if (fontValue >= 0) {
						setIncreaseText(true);
						setDecreaseText(false);
					}
				}
			}
		});
	};

	const toggleDecreaseText = () => {
		const textElements = document.querySelectorAll('*');
		textElements.forEach((element) => {
			const computedStyle = getComputedStyle(element);
			const currentFontSize = computedStyle.fontSize;
			const currentFontSizeValue = parseFloat(currentFontSize);

			if (!isNaN(currentFontSizeValue)) {
				if (fontValue > -2 && fontValue <= 3) {
					setFontValue(fontValue - 1);
					const newFontSize = currentFontSizeValue - 4;
					setFontSizePercent(fontSizePercent - 27);
					element.style.fontSize = newFontSize + 'px';
					if (fontValue <= 0) {
						setIncreaseText(false);
						setDecreaseText(true);
					}
				}
			}
		});
	};

	const toggleFontFamily = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-fonts-simple');
		setFontFamily(!fontFamily);
	};

	const toggleUnderlineAllLinks = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-content-links');
		setUnderlineAllLinks(!underlineAllLinks);
	};

	const toggleUnderlineAllHeaders = () => {
		const rootElement = document.body;
		rootElement.classList.toggle('a11y-content-headers');
		setUnderlineAllHeaders(!underlineAllHeaders);
	};

	const toggleImageAltText = () => {
		const images = document.querySelectorAll('img');

		images.forEach((image) => {
			const alt = image.getAttribute('alt');
			if (alt) {
				if (
					!image.nextSibling ||
					image.nextSibling.nodeName !== 'SPAN' ||
					!image.nextSibling.classList.contains('a11y-image-title')
				) {
					const altSpan = document.createElement('span');
					altSpan.className = 'a11y-image-title';
					altSpan.classList.add('a11y-image-title');
					altSpan.textContent = alt;

					image.parentNode.insertBefore(altSpan, image.nextSibling);
				}

				const altSpan = image.nextSibling;
				if (altSpan.classList.contains('a11y-image-title')) {
					altSpan.style.display = altTextVisible ? 'none' : 'inline';
				}
			}
		});

		altTextVisible = !altTextVisible;
	};

	function toggleBigCursor() {
		document.body.classList.toggle('a11y-cursor-big-white');
		setBigCursor(!bigCursor);
	}

	function toggleBigBlackCursor() {
		document.body.classList.toggle('a11y-cursor-big-black');
		setBigBlackCursor(!bigBlackCursor);
	}

	function toggleZoomUp() {
		document.body.classList.toggle('a11y-zoom-up');
		setZoomUp(!zoomUp);
	}

	function toggleKbd() {
		document.body.classList.toggle('a11y-disable-buttons-keyboard');
		setKeyboard(!keyboard);
	}

	function toggleAnimations() {
		document.body.classList.toggle('a11y-disable-buttons-animations');
		setAnimations(!animations);
	}

	return (
		<section id="work_section" className="work_section">
			<div className="container container_big">
				<div className="text-center">
					<h2 className="h2 fw-800 mb-40 wow fadeInUp d-none d-md-block" data-wow-delay="0.2s">
						Try it at work
					</h2>
					<div className="list2">
						<button
							type="button"
							onClick={() => openSidebar()}
							className="btn btn_blue_border btn_big d-block d-md-none maxw-none w-100">
							Try it free
						</button>
					</div>
				</div>
				<div className="sidenav wrapper_adjustments_blocks">
					<div className="adjustments_top">
						<h3 className="h3 wow fadeInUp" data-wow-delay="0.2s">
							Accessibility adjustments
						</h3>
						<img
							onClick={() => handleCloseMenu()}
							className="d-block d-md-none close_menu"
							src={closeMenu}
							alt="Close Menu"
						/>
						<button
							onClick={() => resetSettings()}
							type="button"
							className="btn btn_transparent d-none d-md-flex w-auto wow fadeInUp"
							data-wow-delay="0.4s">
							<svg
								className="mr-10"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 18.5C8.69 18.5 6 15.81 6 12.5H8C8 14.71 9.79 16.5 12 16.5C14.21 16.5 16 14.71 16 12.5C16 10.26 14.15 8.41 11.84 8.51L13.41 10.08L12 11.5L8 7.5L12 3.5L13.41 4.91L11.81 6.51C15.28 6.4 18 9.18 18 12.5C18 15.81 15.31 18.5 12 18.5Z"
									fill="#06273F"
								/>
							</svg>
							<span>Reset settings</span>
						</button>
					</div>
					<div className="adjustments_center">
						<div className="wrapper_btns d-block d-md-none">
							<button type="button" className="btn btn_blue_border w-auto wow fadeInUp" data-wow-delay="0.2s">
								<svg
									className="mr-10"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M9.98999 8.57005L7.98999 6.57005L3.83999 2.43005L2.42999 3.84005L5.15999 6.57005H4.42999C3.87999 6.57005 3.42999 7.02005 3.42999 7.57005V17.5701C3.42999 18.1201 3.87999 18.5701 4.42999 18.5701H16.43C16.64 18.5701 16.82 18.4901 16.98 18.3901L20.16 21.5701L21.57 20.1601L12.71 11.3001L9.98999 8.57005ZM5.42999 16.5701V8.57005H7.15999L15.16 16.5701H5.42999ZM15.43 8.57005V11.1801L21.43 17.1801V7.07005L17.43 11.0701V7.57005C17.43 7.02005 16.98 6.57005 16.43 6.57005H10.82L12.82 8.57005H15.43Z"
										fill="white"
									/>
								</svg>
								<span>Block animations</span>
							</button>
							<button type="button" className="btn btn_gray wow fadeInUp" data-wow-delay="0.4s">
								<svg
									className="mr-10"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M20 7V17H4V7H20ZM20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM11 8H13V10H11V8ZM11 11H13V13H11V11ZM8 8H10V10H8V8ZM8 11H10V13H8V11ZM5 11H7V13H5V11ZM5 8H7V10H5V8ZM8 14H16V16H8V14ZM14 11H16V13H14V11ZM14 8H16V10H14V8ZM17 11H19V13H17V11ZM17 8H19V10H17V8Z"
										fill="#06273F"
									/>
								</svg>
								<span>Keyboard navigation</span>
							</button>
						</div>
						<div className="row">
							<div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
								<div className="wrapper_adjustments_block">
									<div className="text_md fw-700 mb-10">Color contrast</div>
									<div
										className={uncoloredDisplay ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleUncoloredDisplay()}>
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.8483 10.62V39C18.2435 39 12.8759 33.74 12.8759 27.26C12.8759 24.14 14.113 21.2 16.3678 18.98L24.8483 10.62ZM24.8483 5L13.5743 16.12C10.6809 18.98 8.88506 22.92 8.88506 27.26C8.88506 35.96 16.0286 43 24.8483 43C33.668 43 40.8115 35.96 40.8115 27.26C40.8115 22.92 39.0156 18.98 36.1223 16.12L24.8483 5Z"
											/>
										</svg>
										<div className="text_md">Uncolored display</div>
									</div>
									<div
										className={brightContrast ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleBrightContrast()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M23.9999 44C35.0145 44 43.9539 35.04 43.9539 24C43.9539 12.96 35.0145 4 23.9999 4C12.9853 4 4.04587 12.96 4.04587 24C4.04587 35.04 12.9853 44 23.9999 44ZM25.9953 8.14C33.8572 9.12 39.9631 15.84 39.9631 24C39.9631 32.16 33.8771 38.88 25.9953 39.86V8.14Z"
											/>
										</svg>
										<div className="text_md">Bright contrast</div>
									</div>
									<div
										className={reverseContrast ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleReverseContrast()}>
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.154 4C13.1394 4 4.19999 12.96 4.19999 24C4.19999 35.04 13.1394 44 24.154 44C35.1686 44 44.108 35.04 44.108 24C44.108 12.96 35.1686 4 24.154 4ZM22.1586 39.86C14.2967 38.88 8.1908 32.16 8.1908 24C8.1908 15.84 14.2768 9.12 22.1586 8.14L22.1586 39.86Z"
											/>
										</svg>
										<div className="text_md">Reverse contrast</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
								<div className="wrapper_adjustments_block">
									<div className="text_md fw-700 mb-10">Text size</div>
									<div
										className={
											increaseText
												? 'adjustments_block adjustments_block_between active'
												: 'adjustments_block adjustments_block_between'
										}
										onClick={() => toggleIncreaseText()}>
										<div className="inner_adjustments_block">
											<svg
												width="49"
												height="48"
												viewBox="0 0 49 48"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													className="pfill_01"
													d="M17.403 9V15H27.3035V39H33.2438V15H43.1443V9H17.403ZM5.5224 25H11.4627V39H17.403V25H23.3433V19H5.5224V25Z"
												/>
											</svg>
											<div className="text_md">Increase text</div>
										</div>
										{increaseText && <div className="text_md">+{fontSizePercent}%</div>}
									</div>
									<div
										className={decreaseText ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleDecreaseText()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M5.4657 9V15H15.2206V39H21.0735V15H30.8284V9H5.4657ZM42.5343 19H24.9755V25H30.8284V39H36.6814V25H42.5343V19Z"
											/>
										</svg>
										<div className="text_md">Decrease text</div>
										{decreaseText && <div className="text_md">{fontSizePercent}%</div>}
									</div>
									<div
										className={fontFamily ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleFontFamily()}>
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.1122 30.5H28.2507L18.1323 4.5H14.4494L4.33105 30.5H8.46946L10.6872 24.5H21.8549L24.1122 30.5ZM12.192 20.5L16.2909 9.46L20.3897 20.5H12.192ZM42.2104 21.68L26.1914 37.86L18.9244 30.5L16.1324 33.32L26.2112 43.5L45.0023 24.5L42.2104 21.68Z"
											/>
										</svg>
										<div className="text_md">Readable text</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
								<div className="wrapper_adjustments_block">
									<div className="text_md fw-700 mb-10">Highlighting content</div>
									<div
										className={underlineAllLinks ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleUnderlineAllLinks()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24 32.3334C29.525 32.3334 34 27.8584 34 22.3334V9H29.8334V22.3334C29.8334 25.5584 27.225 28.1667 24 28.1667C20.775 28.1667 18.1666 25.5584 18.1666 22.3334V9H14V22.3334C14 27.8584 18.475 32.3334 24 32.3334Z"
											/>
											<path className="pfill_01" d="M35.6667 35.6667H12.3334V39.0001H35.6667V35.6667Z" />
										</svg>
										<div className="text_md">Underline links</div>
									</div>
									<div
										className={underlineAllHeaders ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleUnderlineAllHeaders()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_30_87)">
												<path
													className="pfill_01"
													d="M35.6667 35.6667H12.3334V39.0001H35.6667V35.6667Z"
												/>
												<path
													className="pfill_01"
													d="M14.0001 32V9H19.5124V18.2314H28.499V9H34.0001V32H28.499V22.7573H19.5124V32H14.0001Z"
												/>
											</g>
											<defs>
												<clipPath id="clip0_30_87">
													<rect
														className="pfill_01"
														width="30"
														height="30"
														transform="translate(9 9)"
													/>
												</clipPath>
											</defs>
										</svg>
										<div className="text_md">Underline headers</div>
									</div>
									<div
										className={imageAltText ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => {
											// setImageAltText(!imageAltText);
											toggleImageAltText();
										}}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M42 8H6C3.8 8 2 9.8 2 12V36C2 38.2 3.8 40 6 40H42C44.2 40 46 38.2 46 36V12C46 9.8 44.2 8 42 8ZM42 36H6V12H42V36ZM29 22L22 31.02L17 25L10 34H38L29 22Z"
											/>
										</svg>
										<div className="text_md">Images titles</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
								<div className="wrapper_adjustments_block">
									<div className="text_md fw-700 mb-10">Zoom in</div>
									<div
										className={bigCursor ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleBigCursor()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												fillRule="evenodd"
												clipRule="evenodd"
												d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45ZM14.5791 13.929L35.792 22.4143L35.7921 22.4146C35.8816 22.4504 35.9589 22.5112 36.0146 22.5899C36.0704 22.6686 36.1022 22.7617 36.1062 22.8581C36.1102 22.9544 36.0863 23.0498 36.0373 23.1329C35.9884 23.216 35.9164 23.2831 35.8302 23.3262L29.8512 26.3157L34.5462 31.0107C34.6399 31.1045 34.6926 31.2316 34.6926 31.3642C34.6926 31.4968 34.6399 31.624 34.5462 31.7177L31.7176 34.5461C31.6712 34.5925 31.6161 34.6294 31.5554 34.6545C31.4947 34.6797 31.4297 34.6926 31.3641 34.6926C31.2984 34.6926 31.2334 34.6797 31.1727 34.6545C31.1121 34.6294 31.057 34.5925 31.0106 34.5461L26.3162 29.8511L23.3262 35.8303C23.2847 35.9134 23.2208 35.9832 23.1418 36.032C23.0629 36.0809 22.9718 36.1067 22.879 36.1067L22.858 36.1062C22.7617 36.1021 22.6687 36.0704 22.59 36.0147C22.5114 35.9591 22.4504 35.8819 22.4146 35.7925L13.9291 14.579C13.8927 14.4881 13.8838 14.3886 13.9035 14.2927C13.9232 14.1968 13.9706 14.1088 14.0398 14.0396C14.109 13.9704 14.197 13.9231 14.2928 13.9034C14.3887 13.8837 14.4883 13.8926 14.5791 13.929Z"
											/>
										</svg>
										<div className="text_md">Big white cursor</div>
									</div>
									<div
										className={bigBlackCursor ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleBigBlackCursor()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_1_513)">
												<path
													className="pfill_01"
													d="M37.3734 20.6514L10.8572 10.0448C10.7436 9.99929 10.6192 9.98816 10.4994 10.0127C10.3795 10.0373 10.2695 10.0966 10.183 10.1831C10.0965 10.2696 10.0373 10.3796 10.0127 10.4994C9.98813 10.6193 9.99926 10.7437 10.0447 10.8573L20.6515 37.3741C20.6964 37.4859 20.7725 37.5824 20.8708 37.6519C20.9692 37.7215 21.0855 37.7612 21.2058 37.7663L21.232 37.7669C21.3481 37.7669 21.4619 37.7346 21.5606 37.6736C21.6594 37.6126 21.7392 37.5253 21.7911 37.4214L25.5286 29.9474L31.3965 35.8161C31.4546 35.8742 31.5234 35.9202 31.5993 35.9517C31.6751 35.9831 31.7563 35.9993 31.8384 35.9993C31.9205 35.9993 32.0018 35.9831 32.0776 35.9517C32.1534 35.9202 32.2223 35.8742 32.2803 35.8161L35.816 32.2807C35.9332 32.1635 35.9991 32.0045 35.9991 31.8388C35.9991 31.673 35.9332 31.5141 35.816 31.3969L29.9473 25.5281L37.421 21.7913C37.5289 21.7374 37.6188 21.6535 37.68 21.5496C37.7412 21.4458 37.7711 21.3265 37.7661 21.2061C37.7611 21.0857 37.7213 20.9693 37.6516 20.8709C37.582 20.7726 37.4853 20.6965 37.3734 20.6518L37.3734 20.6514Z"
												/>
											</g>
											<defs>
												<clipPath id="clip0_1_513">
													<rect
														className="pfill_01"
														width="30"
														height="30"
														transform="translate(10 10)"
													/>
												</clipPath>
											</defs>
										</svg>
										<div className="text_md">Big black cursor</div>
									</div>
									<div
										className={zoomUp ? 'adjustments_block active' : 'adjustments_block'}
										onClick={() => toggleZoomUp()}>
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M31.51 28.51H29.93L29.37 27.97C31.33 25.69 32.51 22.73 32.51 19.51C32.51 12.33 26.69 6.51001 19.51 6.51001C12.33 6.51001 6.51001 12.33 6.51001 19.51C6.51001 26.69 12.33 32.51 19.51 32.51C22.73 32.51 25.69 31.33 27.97 29.37L28.51 29.93V31.51L38.51 41.49L41.49 38.51L31.51 28.51ZM19.51 28.51C14.53 28.51 10.51 24.49 10.51 19.51C10.51 14.53 14.53 10.51 19.51 10.51C24.49 10.51 28.51 14.53 28.51 19.51C28.51 24.49 24.49 28.51 19.51 28.51ZM20.51 14.51H18.51V18.51H14.51V20.51H18.51V24.51H20.51V20.51H24.51V18.51H20.51V14.51Z"
											/>
										</svg>
										<div className="text_md">Zoom screen</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="adjustments_bottom">
						<div className="row">
							<div className="col-md-6 d-none d-md-block">
								<div className="wrapper_btns">
									<button
										onClick={() => toggleAnimations()}
										type="button"
										className={
											animations
												? 'btn btn_blue_border w-auto wow fadeInUp'
												: 'btn btn_gray w-auto wow fadeInUp'
										}
										data-wow-delay="0.2s">
										<svg
											className="mr-10"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.98999 8.57005L7.98999 6.57005L3.83999 2.43005L2.42999 3.84005L5.15999 6.57005H4.42999C3.87999 6.57005 3.42999 7.02005 3.42999 7.57005V17.5701C3.42999 18.1201 3.87999 18.5701 4.42999 18.5701H16.43C16.64 18.5701 16.82 18.4901 16.98 18.3901L20.16 21.5701L21.57 20.1601L12.71 11.3001L9.98999 8.57005ZM5.42999 16.5701V8.57005H7.15999L15.16 16.5701H5.42999ZM15.43 8.57005V11.1801L21.43 17.1801V7.07005L17.43 11.0701V7.57005C17.43 7.02005 16.98 6.57005 16.43 6.57005H10.82L12.82 8.57005H15.43Z"
												fill="white"
											/>
										</svg>
										<span>Block animations</span>
									</button>
									<button
										type="button"
										className={
											keyboard
												? 'btn btn_blue_border w-auto wow fadeInUp'
												: 'btn btn_gray w-auto wow fadeInUp'
										}
										data-wow-delay="0.4s"
										onClick={() => toggleKbd()}>
										<svg
											className="mr-10"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M20 7V17H4V7H20ZM20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM11 8H13V10H11V8ZM11 11H13V13H11V11ZM8 8H10V10H8V8ZM8 11H10V13H8V11ZM5 11H7V13H5V11ZM5 8H7V10H5V8ZM8 14H16V16H8V14ZM14 11H16V13H14V11ZM14 8H16V10H14V8ZM17 11H19V13H17V11ZM17 8H19V10H17V8Z"
												fill="#06273F"
											/>
										</svg>
										<span>Keyboard navigation</span>
									</button>
								</div>
							</div>
							<div className="col-md-6">
								<div className="adjustments_right_btns">
									<div className="wrapper_btns">
										<button
											type="button"
											className="btn btn_transparent d-flex d-md-none w-auto wow fadeInUp"
											data-wow-delay="0.4s">
											<svg
												className="mr-10"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 18.5C8.69 18.5 6 15.81 6 12.5H8C8 14.71 9.79 16.5 12 16.5C14.21 16.5 16 14.71 16 12.5C16 10.26 14.15 8.41 11.84 8.51L13.41 10.08L12 11.5L8 7.5L12 3.5L13.41 4.91L11.81 6.51C15.28 6.4 18 9.18 18 12.5C18 15.81 15.31 18.5 12 18.5Z"
													fill="#06273F"
												/>
											</svg>
											<span>Reset settings</span>
										</button>
										<button
											type="button"
											className="btn btn_transparent w-auto wow fadeInUp"
											data-wow-delay="0.6s">
											<span>Accessibility statement</span>
										</button>
										<button
											type="button"
											className="btn btn_transparent w-auto wow fadeInUp"
											data-wow-delay="0.8s">
											<span>Report problem</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default AccessibilityAdjustments;
