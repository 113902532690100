/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLoginTokenUser, useVerifyEmail } from '../factory/useData';
import emailImg from '../img/svg/email_success.svg';
import { login } from '../reducer';
import Loading from './Loading';
import { pages } from '../factory/apiConfig';

const VerificationSuccess = () => {
	const { token } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSuccess = (data) => {
		if (data && data.access_token) {
			localStorage.setItem('token', data.access_token);
			dispatch(login());
			navigate(pages.sites);
			toast.success('Successfully login!');
		}
	};

	const onError = () => {
		navigate(pages.login);
		toast.warn('Please login!');
	};

	const onSuccessVerify = (data) => {
		if (data && data.access_token) {
			localStorage.setItem('token', data.access_token);
		}
	};

	const onErrorVerify = () => {
		toast.warn('Please login!');
	};

	const { mutate: mutateVerify, isLoading } = useVerifyEmail(onSuccessVerify, onErrorVerify);
	const { mutate } = useLoginTokenUser(onSuccess, onError);

	useEffect(() => {
		mutateVerify(token);
	}, [token]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div className="d-flex align-items-center justify-content-center h-full" style={{ flexDirection: 'column' }}>
			<img src={emailImg} alt="Email verified" style={{ width: '12rem' }} />
			<p className="error_text" style={{ textAlign: 'center' }}>
				Your email has been successfully verified!
			</p>
			<Link
				onClick={() => {
					if (localStorage.getItem('token')) {
						mutate(localStorage.getItem('token'));
					} else {
						navigate(pages.login);
					}
				}}
				className="btn btn_blue">
				Login
			</Link>
		</div>
	);
};
export default VerificationSuccess;
