const Help = () => {
  return (
		<section className="helps_section">
			<div className="container">
				<div className="help_block text-center wow fadeInUp" data-wow-delay="0.4s">
					<div className="text fw-700 color_white">Helps with WCAG 2.1, ADA, Section 508 and EN 301549 compliance</div>
				</div>
			</div>
		</section>
  );
}
export default Help