import $ from 'jquery';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import WOW from 'wowjs';
import { Routes } from './components/Routes';
import './dependencies/wow/css/animate.css';
import { pages } from './factory/apiConfig';

const queryClient = new QueryClient();

function App() {
	useEffect(() => {
		const wow = new WOW.WOW({
			boxClass: 'wow',
			animateClass: 'animated',
			offset: 0,
			mobile: false,
			live: true,
			scrollContainer: null,
		});
		wow.init();

		$(window).scroll(function () {
			const showAfter = 100;
			if ($(this).scrollTop() > showAfter) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});

		$('.back-to-top').click(function () {
			$('html, body').animate({ scrollTop: 0 }, 800);
			return false;
		});

		$(window).bind('scroll', function () {
			const navHeight = $(window).height() - $(window).height();
			if ($(window).scrollTop() > navHeight) {
				$('#header').addClass('on');
			} else {
				$('#header').removeClass('on');
			}
		});
	}, []);

	const location = useLocation();
	const { pathname } = location;

	const isPathInRoutes = (path) => {
		for (const key in pages) {
			if (pages.hasOwnProperty(key)) {
				if (pages[key] === path) {
					return true;
				}

				const routeWithParamRegex = new RegExp(`^${pages[key].replace(/:[^/]+/g, '[^/]+')}$`);
				if (routeWithParamRegex.test(path)) {
					return true;
				}
			}
		}
		return false;
	};

	useEffect(() => {
		const handleMutations = () => {
			const a11yWidget = document.getElementById('a11y-widget');
			if (a11yWidget) {
				if (
					pathname === pages.login ||
					pathname === pages.register ||
					pathname === pages.reset ||
					pathname.includes(pages.verify.replace('/:token', '')) ||
					!isPathInRoutes(pathname)
				) {
					a11yWidget.style.display = 'none';
				} else {
					a11yWidget.style.display = 'block';
				}
				observer.disconnect();
			}
		};

		const observer = new MutationObserver(handleMutations);
		observer.observe(document.body, { subtree: true, childList: true });

		return () => {
			observer.disconnect();
		};
	}, [pathname]);

	return (
		<QueryClientProvider client={queryClient}>
			<Routes />
			{/* <CookieConsent
				location="bottom"
				expires={15}
				buttonText="Accept"
				enableDeclineButton
				declineButtonText="Decline"
				cookieName="a11yWidgetCookieConsent"
				style={{
					background: '#fff',
					color: '#000',
					fontWeight: '600',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					boxShadow: '0px 0px 26px -8px rgba(0,0,0,0.75)',
				}}
				buttonStyle={{
					background: '#2F5BDF',
					color: '#fff',
					fontSize: '16px',
					fontWeight: '600',
					borderRadius: '8px',
					padding: '10px 15px',
				}}
				declineButtonStyle={{
					color: '#fff',
					fontSize: '16px',
					fontWeight: '600',
					borderRadius: '8px',
					padding: '10px 15px',
				}}
				>
				This website uses cookies to enhance the user experience.
			</CookieConsent> */}
		</QueryClientProvider>
	);
}

export default App;
