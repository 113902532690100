export const api = {
	// url: 'http://localhost:4000/api',
	url: 'https://app.a11ywidget.com/api',
	// user
	user: 'user',
	adminUsers: 'user/admin',
	// auth
	register: 'auth/register',
	login: 'auth/login',
	tokenLogin: 'auth/token-login',
	reset: 'auth/reset',
	verify: 'auth/verify',
	// resources
	resources: 'resources',
	newResource: 'resources/new',
	updateResource: 'resources/update',
	deleteResource: 'resources/delete',
	//widget
	widget: 'widget',
	widgetUpdate: 'widget/update',
	widgetDelete: 'widget/delete',
};

export const pages = {
	main: '/welcome',
	login: '/login',
	register: '/register',
	newsite: '/newsite',
	editsite: '/editsite/:id',
	profile: '/profile',
	reset: '/reset',
	verify: '/verify/:token',
	sites: '/',
	admin: '/admin',
};
