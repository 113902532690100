import { Link } from 'react-router-dom';
import errorImg from '../img/svg/error.svg';
import { pages } from '../factory/apiConfig';

const ErrorPage = () => {
	return (
		<div className="d-flex align-items-center justify-content-center h-full" style={{ flexDirection: 'column' }}>
			<img src={errorImg} alt="Error" style={{width:'12rem'}} />
			<p className="error_text" style={{textAlign:'center'}}>Oops! There was an error!</p>
			<Link to={pages.sites} className="btn btn_blue">
				Go home
			</Link>
		</div>
	);
};
export default ErrorPage;
