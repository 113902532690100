/* eslint-disable jsx-a11y/anchor-is-valid */
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api, pages } from '../factory/apiConfig';
import closeImg from '../img/svg/clear.svg';

const Modal = ({ domain, id, instance_id }) => {
	const navigate = useNavigate();

	const deleteResource = async () => {
		await fetch(`${api.url}/${api.deleteResource}/${id}`, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
			.then()
			.catch((err) => {
				toast.error('Failed to delete!');
			});

		await fetch(`${api.url}/${api.widgetDelete}/${instance_id}`, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
			.then((res) => {
				$('.modal').removeClass('show');
				$('body').removeClass('modal-open');
				navigate(pages.sites);
				toast.success('Successfully deleted!');
			})
			.catch((err) => {
				toast.error('Failed to delete!');
			});
	};

	return (
		<>
			<div className="modal-overlay" id="modal-overlay"></div>

			<div
				className="modal fade"
				id="exModalLive"
				tabIndex="-1"
				aria-labelledby="exModalLiveLabel"
				aria-modal="true"
				role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<div
								className="close-modal"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									$('.modal').removeClass('show');
									$('body').removeClass('modal-open');
								}}>
								<img src={closeImg} alt="clear" />
							</div>
						</div>
						<div className="modal-body">
							<h3 className="h3 mb-10 mt-10 word-wrap">Confirm removing widget from {domain}</h3>
							<div className="text_lg fw-400 mb-25 ">
								This means that your website would not ADA & WCAG compliant
							</div>
							<a className="btn btn_red mb-15" onClick={() => deleteResource()}>
								Yes, remove
							</a>
							<a
								className="btn btn_green"
								onClick={() => {
									$('.modal').removeClass('show');
									$('body').removeClass('modal-open');
								}}>
								Keep it compliant
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Modal;
