/* eslint-disable react-hooks/exhaustive-deps */
import { LogOut } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pages } from "../factory/apiConfig";
import { useFetchUserProfile, useUpdateUser } from "../factory/useData";
import { logout } from "../reducer";
import "../styles/profile.css";
import Footer from "./Footer";
import Loading from "./Loading";
import Navbar from "./Navbar";

const ProfilePage = () => {
  const isAuth = useSelector((state) => state.reducer.isAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const onSuccess = (data) => {
    setIsEdit(false);
    toast.success("Successfully changed email!");
  };

  const onError = (data) => {
    setIsEdit(false);
    toast.error("Failed to change email!");
  };

  const { mutate, isLoading } = useUpdateUser(onSuccess, onError);

  useEffect(() => {
    if (!isAuth) {
      navigate(pages.login);
    }
  }, [isAuth]);

  const [user, isLoadingUser] = useFetchUserProfile(
    localStorage.getItem("token"),
    isAuth
  );

  if (isLoading || isLoadingUser) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <main className="main_content d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="navigation_wrapper">
            <Link className="hover_text" to={pages.sites}>
              Webresources
            </Link>
            <span>{">"}</span>
            <Link className="hover_text" to={pages.profile}>
              Profile
            </Link>
          </div>
          {user && (
            <div className="profile_wrapper h-full">
              <div className="profile_card">
                <div className="profile_icon">
                  <span className="profile_icon_text">{user.user_name[0]}</span>
                </div>
                <div className="profile_info">
                  <span className="profile_name">{user.user_name}</span>
                  <span className="profile_email">{user.user_email}</span>
                </div>
              </div>
              {user.role === "admin" && (
                <div className="profile_info">
                  <span
                    className="edit_email_button"
                    onClick={() => navigate(pages.admin)}
                  >
                    Admin page
                  </span>
                </div>
              )}
              {isEdit ? (
                <div className="profile_info">
                  <span className="profile_info_text">
                    Write your new email:
                  </span>
                  <input
                    className="profile_input"
                    type="text"
                    name="email"
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  <div className="email_buttons_wrapper">
                    <span
                      className="done_email_button"
                      onClick={() => {
                        if (newEmail === user.user_email) {
                          toast.error("Email is the same!");
                        } else if (newEmail) {
                          mutate({
                            userId: user.user_id,
                            credentials: {
                              user_email: newEmail,
                              is_verified: false,
                            },
                          });
                        }
                      }}
                    >
                      Save
                    </span>
                    <span
                      className="edit_email_button"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      Close
                    </span>
                  </div>
                </div>
              ) : (
                <div className="profile_info">
                  <span
                    className="edit_email_button"
                    onClick={() => setIsEdit(true)}
                  >
                    Edit email
                  </span>
                </div>
              )}
              <div
                className="profile_logout"
                onClick={() => {
                  dispatch(logout());
                  toast.success("Successfully logout!");
                  navigate(pages.login);
                }}
              >
                Logout
                <LogOut />
              </div>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ProfilePage;
