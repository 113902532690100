/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFetchUserProfile, useFetchUserResources } from "../factory/useData";
import siteImg from "../img/png/mask_websites.png";
import addSite from "../img/svg/add-circle.svg";
import Footer from "./Footer";
import Loading from "./Loading";
import Navbar from "./Navbar";
import { pages } from "../factory/apiConfig";

const SitesLayout = () => {
  const isAuth = useSelector((state) => state.reducer.isAuth);
  const navigate = useNavigate();

  const [user, isLoadingUser] = useFetchUserProfile(
    localStorage.getItem("token"),
    isAuth
  );

  const [resources, isLoadingResources] = useFetchUserResources(
    localStorage.getItem("token"),
    isAuth
  );

  useEffect(() => {
    if (!isAuth) {
      navigate(pages.login);
    }
  }, [isAuth, navigate]);

  if (isLoadingResources || isLoadingUser) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <main className="main_content d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="wrapper_add_website_block h-full">
            {resources && resources.length !== 0 && (
              <>
                <div className="title_sign mb-30">
                  Your webresources with widget
                </div>
                <div className="row">
                  {resources.map((site) => {
                    const {
                      resource_domain_name,
                      resource_id,
                      resource_image,
                    } = site;
                    return (
                      <div key={resource_id} className="col-sm-6 col-lg-4">
                        <div
                          className="website_block"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(
                              pages.editsite.replace(":id", resource_id)
                            );
                          }}
                        >
                          <div className="top_website_block">
                            {resource_image ? (
                              <img
                                className="img-fluid w-100"
                                src={`data:image/png;base64,${resource_image}`}
                                alt="websites"
                              />
                            ) : (
                              <img
                                className="img-fluid w-100"
                                src={siteImg}
                                alt="websites"
                              />
                            )}
                          </div>
                          <div className="bottom_website_block">
                            <a
                              href="#"
                              className="text_lg color_dark_blue d-block domain_name"
                            >
                              {resource_domain_name}
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-sm-6 col-lg-4">
                    <div
                      className="add_website"
                      onClick={() => {
                        navigate(pages.newsite);
                      }}
                    >
                      <div className="mb-10">
                        <img src={addSite} alt="add website" />
                      </div>
                      <div className="text_lg color_blue">Add website</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {resources && resources.length === 0 && (
              <div className="sign_block">
                <div className="text-center">
                  <div className="title_sign mb-25">
                    Hello, {user && user.user_name}! <br />
                    Add your first website
                  </div>
                </div>

                <div
                  className="add_website"
                  onClick={() => navigate(pages.newsite)}
                >
                  <div className="mb-10">
                    <img src={addSite} alt="add website" />
                  </div>
                  <div className="text_lg color_blue">Add website</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default SitesLayout;
