const AccessibilityFeatures = () => {
	return (
		<section className="features_section">
			<div className="container">
				<div className="text-center">
					<h2 className="h2 fw-800 wow fadeInUp" data-wow-delay="0.2s">
						Accessibility features
					</h2>
				</div>
				<div className="features_line">
					<div className="row">
						<div className="col-lg-7">
							<div className="container_sm wow fadeInLeft" data-wow-delay="0.3s">
								<h2 className="h2 mb-05">Color contrast</h2>
								<div className="text">
									Make sure that the contrast between the elements of your content is legible
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="wrapper_features_blocks">
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.2s">
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.8483 10.62V39C18.2435 39 12.8759 33.74 12.8759 27.26C12.8759 24.14 14.113 21.2 16.3678 18.98L24.8483 10.62ZM24.8483 5L13.5743 16.12C10.6809 18.98 8.88506 22.92 8.88506 27.26C8.88506 35.96 16.0286 43 24.8483 43C33.668 43 40.8115 35.96 40.8115 27.26C40.8115 22.92 39.0156 18.98 36.1223 16.12L24.8483 5Z"
											/>
										</svg>
										<div className="text_md">
											Uncolored <br /> display
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block active wow fadeInUp" data-wow-delay="0.4s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M23.9999 44C35.0145 44 43.9539 35.04 43.9539 24C43.9539 12.96 35.0145 4 23.9999 4C12.9853 4 4.04587 12.96 4.04587 24C4.04587 35.04 12.9853 44 23.9999 44ZM25.9953 8.14C33.8572 9.12 39.9631 15.84 39.9631 24C39.9631 32.16 33.8771 38.88 25.9953 39.86V8.14Z"
											/>
										</svg>
										<div className="text_md">
											Bright <br /> contrast
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.6s">
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.154 4C13.1394 4 4.19999 12.96 4.19999 24C4.19999 35.04 13.1394 44 24.154 44C35.1686 44 44.108 35.04 44.108 24C44.108 12.96 35.1686 4 24.154 4ZM22.1586 39.86C14.2967 38.88 8.1908 32.16 8.1908 24C8.1908 15.84 14.2768 9.12 22.1586 8.14L22.1586 39.86Z"
											/>
										</svg>
										<div className="text_md">
											Reverse <br /> contrast
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="features_line">
					<div className="row">
						<div className="col-lg-7">
							<div className="container_md wow fadeInLeft" data-wow-delay="0.3s">
								<h2 className="h2 mb-05">Text size</h2>
								<div className="text">
									To enhance readability and ensure that individuals with diverse visual abilities can easily
									read the content of your website.
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="wrapper_features_blocks">
								<div className="features_block">
									<div className="inner_features_block active wow fadeInUp" data-wow-delay="0.2s">
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M17.403 9V15H27.3035V39H33.2438V15H43.1443V9H17.403ZM5.5224 25H11.4627V39H17.403V25H23.3433V19H5.5224V25Z"
											/>
										</svg>
										<div className="text_md">
											Increase <br /> text
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.4s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M5.4657 9V15H15.2206V39H21.0735V15H30.8284V9H5.4657ZM42.5343 19H24.9755V25H30.8284V39H36.6814V25H42.5343V19Z"
											/>
										</svg>
										<div className="text_md">
											Decrease <br /> text
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.6s">
										<svg
											width="49"
											height="48"
											viewBox="0 0 49 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24.1122 30.5H28.2507L18.1323 4.5H14.4494L4.33105 30.5H8.46946L10.6872 24.5H21.8549L24.1122 30.5ZM12.192 20.5L16.2909 9.46L20.3897 20.5H12.192ZM42.2104 21.68L26.1914 37.86L18.9244 30.5L16.1324 33.32L26.2112 43.5L45.0023 24.5L42.2104 21.68Z"
											/>
										</svg>
										<div className="text_md">
											Readable <br /> text
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="features_line">
					<div className="row">
						<div className="col-lg-7">
							<div className="container_sm wow fadeInLeft" data-wow-delay="0.3s">
								<h2 className="h2 mb-05">Highlight Content</h2>
								<div className="text">
									Highlight website elements with an anchor text to ensure better user experience
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="wrapper_features_blocks">
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.2s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M24 32.3334C29.525 32.3334 34 27.8584 34 22.3334V9H29.8334V22.3334C29.8334 25.5584 27.225 28.1667 24 28.1667C20.775 28.1667 18.1666 25.5584 18.1666 22.3334V9H14V22.3334C14 27.8584 18.475 32.3334 24 32.3334Z"
											/>
											<path className="pfill_01" d="M35.6667 35.6667H12.3334V39.0001H35.6667V35.6667Z" />
										</svg>
										<div className="text_md">
											Underline <br /> links
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block active wow fadeInUp" data-wow-delay="0.4s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_30_87)">
												<path
													className="pfill_01"
													d="M35.6667 35.6667H12.3334V39.0001H35.6667V35.6667Z"
												/>
												<path
													className="pfill_01"
													d="M14.0001 32V9H19.5124V18.2314H28.499V9H34.0001V32H28.499V22.7573H19.5124V32H14.0001Z"
												/>
											</g>
											<defs>
												<clipPath id="clip0_30_87">
													<rect
														className="pfill_01"
														width="30"
														height="30"
														transform="translate(9 9)"
													/>
												</clipPath>
											</defs>
										</svg>
										<div className="text_md">
											Underline <br /> headers
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.6s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M42 8H6C3.8 8 2 9.8 2 12V36C2 38.2 3.8 40 6 40H42C44.2 40 46 38.2 46 36V12C46 9.8 44.2 8 42 8ZM42 36H6V12H42V36ZM29 22L22 31.02L17 25L10 34H38L29 22Z"
											/>
										</svg>
										<div className="text_md">
											Images <br /> titles
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="features_line">
					<div className="row">
						<div className="col-lg-7">
							<div className="container_sm wow fadeInLeft" data-wow-delay="0.3s">
								<h2 className="h2 mb-05">Zoom in</h2>
								<div className="text">Enlarge cursor or add bigger text for your website visitors.</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="wrapper_features_blocks">
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.2s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												fillRule="evenodd"
												clipRule="evenodd"
												d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45ZM14.5791 13.929L35.792 22.4143L35.7921 22.4146C35.8816 22.4504 35.9589 22.5112 36.0146 22.5899C36.0704 22.6686 36.1022 22.7617 36.1062 22.8581C36.1102 22.9544 36.0863 23.0498 36.0373 23.1329C35.9884 23.216 35.9164 23.2831 35.8302 23.3262L29.8512 26.3157L34.5462 31.0107C34.6399 31.1045 34.6926 31.2316 34.6926 31.3642C34.6926 31.4968 34.6399 31.624 34.5462 31.7177L31.7176 34.5461C31.6712 34.5925 31.6161 34.6294 31.5554 34.6545C31.4947 34.6797 31.4297 34.6926 31.3641 34.6926C31.2984 34.6926 31.2334 34.6797 31.1727 34.6545C31.1121 34.6294 31.057 34.5925 31.0106 34.5461L26.3162 29.8511L23.3262 35.8303C23.2847 35.9134 23.2208 35.9832 23.1418 36.032C23.0629 36.0809 22.9718 36.1067 22.879 36.1067L22.858 36.1062C22.7617 36.1021 22.6687 36.0704 22.59 36.0147C22.5114 35.9591 22.4504 35.8819 22.4146 35.7925L13.9291 14.579C13.8927 14.4881 13.8838 14.3886 13.9035 14.2927C13.9232 14.1968 13.9706 14.1088 14.0398 14.0396C14.109 13.9704 14.197 13.9231 14.2928 13.9034C14.3887 13.8837 14.4883 13.8926 14.5791 13.929Z"
											/>
										</svg>
										<div className="text_md">
											Big white <br /> cursor
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block active wow fadeInUp" data-wow-delay="0.4s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#clip0_1_513)">
												<path
													className="pfill_01"
													d="M37.3734 20.6514L10.8572 10.0448C10.7436 9.99929 10.6192 9.98816 10.4994 10.0127C10.3795 10.0373 10.2695 10.0966 10.183 10.1831C10.0965 10.2696 10.0373 10.3796 10.0127 10.4994C9.98813 10.6193 9.99926 10.7437 10.0447 10.8573L20.6515 37.3741C20.6964 37.4859 20.7725 37.5824 20.8708 37.6519C20.9692 37.7215 21.0855 37.7612 21.2058 37.7663L21.232 37.7669C21.3481 37.7669 21.4619 37.7346 21.5606 37.6736C21.6594 37.6126 21.7392 37.5253 21.7911 37.4214L25.5286 29.9474L31.3965 35.8161C31.4546 35.8742 31.5234 35.9202 31.5993 35.9517C31.6751 35.9831 31.7563 35.9993 31.8384 35.9993C31.9205 35.9993 32.0018 35.9831 32.0776 35.9517C32.1534 35.9202 32.2223 35.8742 32.2803 35.8161L35.816 32.2807C35.9332 32.1635 35.9991 32.0045 35.9991 31.8388C35.9991 31.673 35.9332 31.5141 35.816 31.3969L29.9473 25.5281L37.421 21.7913C37.5289 21.7374 37.6188 21.6535 37.68 21.5496C37.7412 21.4458 37.7711 21.3265 37.7661 21.2061C37.7611 21.0857 37.7213 20.9693 37.6516 20.8709C37.582 20.7726 37.4853 20.6965 37.3734 20.6518L37.3734 20.6514Z"
												/>
											</g>
											<defs>
												<clipPath id="clip0_1_513">
													<rect
														className="pfill_01"
														width="30"
														height="30"
														transform="translate(10 10)"
													/>
												</clipPath>
											</defs>
										</svg>
										<div className="text_md">
											Big black <br /> cursor
										</div>
									</div>
								</div>
								<div className="features_block">
									<div className="inner_features_block wow fadeInUp" data-wow-delay="0.6s">
										<svg
											width="48"
											height="48"
											viewBox="0 0 48 48"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												className="pfill_01"
												d="M31.51 28.51H29.93L29.37 27.97C31.33 25.69 32.51 22.73 32.51 19.51C32.51 12.33 26.69 6.51001 19.51 6.51001C12.33 6.51001 6.51001 12.33 6.51001 19.51C6.51001 26.69 12.33 32.51 19.51 32.51C22.73 32.51 25.69 31.33 27.97 29.37L28.51 29.93V31.51L38.51 41.49L41.49 38.51L31.51 28.51ZM19.51 28.51C14.53 28.51 10.51 24.49 10.51 19.51C10.51 14.53 14.53 10.51 19.51 10.51C24.49 10.51 28.51 14.53 28.51 19.51C28.51 24.49 24.49 28.51 19.51 28.51ZM20.51 14.51H18.51V18.51H14.51V20.51H18.51V24.51H20.51V20.51H24.51V18.51H20.51V14.51Z"
											/>
										</svg>
										<div className="text_md">
											Zoom <br /> screen
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default AccessibilityFeatures;
