import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { login, logout } from '../reducer';
import { pages } from './apiConfig';
import { useLoginTokenUser } from './useData';

const AuthProvider = ({ children }) => {
	const isAuth = useSelector((state) => state.reducer.isAuth);
	const [isLoading, setIsLoading] = useState(true);

	const dispatch = useDispatch();

	const onSuccess = (data) => {
		if (data && data.access_token) {
			localStorage.setItem('token', data.access_token);
			dispatch(login());
		}
	};

	const onError = () => {
		dispatch(logout());
	};

	const { mutate, isLoading: isLoadingTokenLogin } = useLoginTokenUser(onSuccess, onError);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			mutate(token);
		}

		setIsLoading(false);
	}, [mutate]);

	if (isLoading || isLoadingTokenLogin) {
		return null;
	}

	return <>{isAuth ? children : <Navigate to={pages.login} replace />}</>;
};
export default AuthProvider;
