import '../styles/loader.css';

const Loading = () => {
	return (
		<div className="loader_wrapper">
			<div className="center">
				<div className="blue"></div>
				<div className="blue"></div>
				<div className="blue"></div>
			</div>
		</div>
	);
};
export default Loading;
