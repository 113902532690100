import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pages } from '../factory/apiConfig';
import { useFetchUserProfile } from '../factory/useData';
import logo from '../img/svg/logo.svg';
import '../styles/navbar.css';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Navbar = () => {
	const isAuth = useSelector((state) => state.reducer.isAuth);

	const [user] = useFetchUserProfile(localStorage.getItem('token'), isAuth);

	return (
		<header id="header" className="header header_sticky">
			<nav className="navbar navbar-dop">
				<div className="container">
					<div className="nav_block">
						<a href="https://a11ywidget.com" className="wrapper_logo">
							<img className="img-fluid logo_header" src={logo} alt="logo" />
						</a>

						{!isAuth ? (
							<div className="wrapper_contact">
								<Link to={pages.login} type="button" className="btn btn_transparent">
									Login
								</Link>
								<Link to={pages.register} type="button" className="btn btn_blue">
									Sign up
								</Link>
							</div>
						) : (
							<div className="wrapper_contact">
								<Link className="user_icon" to={pages.profile}>
									<span className="user_icon_text">{user && user.user_name[0]}</span>
								</Link>
							</div>
						)}
					</div>
					{isAuth && user && !user.is_verified && (
						<div className="verify_wrapper">
							<span className="verify_text">Please verify your email!</span>
						</div>
					)}
				</div>
			</nav>
		</header>
	);
};
export default Navbar;
