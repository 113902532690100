import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './dependencies/wow/css/animate.css';
import './index.css';
import { store } from './store';
import './styles/widgetInteract.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		<ToastContainer
			limit={2}
			role="alert"
			transition={Zoom}
			draggable
			autoClose={3000}
			position="top-center"
			style={{ fontSize: '16px' }}
		/>
	</Provider>
);
