import logoFooter from '../img/svg/logo_footer.svg';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="top_footer">
					<div className="nav_block">
						<a href="#" className="wrapper_logo wow fadeInLeft" data-wow-delay="0.2s">
							<img className="img-fluid logo_footer" src={logoFooter} alt="logo footer" />
						</a>
						<div className="wow fadeInRight" data-wow-delay="0.4s">
							<a href="mailto:contact@a11y.com" className="text_sm color_white_light">
								contact@a11ywidget.com
							</a>
						</div>
					</div>
				</div>
				<div className="bottom_footer wow fadeInUp" data-wow-delay="0.4s">
					<a href="#" className="color_white_light text_min">
						© a11y.widget
					</a>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
