import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isAuth: false,
};

export const widget = createSlice({
	name: 'widget',
	initialState,
	reducers: {
		login: (state) => {
			state.isAuth = true;
		},
		logout: (state) => {
			state.isAuth = false;
			localStorage.clear();
		},
	},
});

export const { login, logout } = widget.actions;

export default widget.reducer;
