/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import $ from 'jquery';
import BackToTop from './BackToTop';
import Footer from './Footer';
import Navbar from './Navbar';
import AccessibilityAdjustments from './mainSectionContent/AccessibilityAdjustments';
import AccessibilityFeatures from './mainSectionContent/AccessibilityFeatures';
import Help from './mainSectionContent/Help';
import HowItWorks from './mainSectionContent/HowItWorks';
import WelcomeContent from './mainSectionContent/WelcomeContent';

const MainContent = () => {
	const openSidebar = () => {
		$('.main-wrapper').addClass('sidebar-open');
	};

	const handleCloseMenu = () => {
		$('.main-wrapper').removeClass('sidebar-open');
	};

	return (
		<>
			<div className="main-wrapper">
				<Navbar />
				<WelcomeContent />

				<div className="bg_gray">
					<AccessibilityFeatures />

					<AccessibilityAdjustments
						handleCloseMenu={handleCloseMenu}
						openSidebar={openSidebar}
					/>

					<Help />

					<HowItWorks />
				</div>
				<Footer />
			</div>
			<BackToTop />
		</>
	);
};
export default MainContent;
