const HowItWorks = () => {
	return (
		<section className="how_it_works_section">
			<div className="container">
				<div className="how_it_works_block">
					<div className="text-center">
						<h2 className="h2 fw-800 mb-15 wow fadeInUp" data-wow-delay="0.4s">
							How it works
						</h2>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="item_work wow fadeInUp" data-wow-delay="0.3s">
								<div className="text-center">
									<div className="text fw-700">1</div>
									<div className="text fw-700">Create an account</div>
									<div className="text">
										As you always do. It’s for free <br className="d-desktop" /> and no credit card required
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="item_work wow fadeInUp" data-wow-delay="0.6s">
								<div className="text-center">
									<div className="text fw-700">2</div>
									<div className="text fw-700">Add your website</div>
									<div className="text">
										With the simple interface. <br /> No rocket science!
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="item_work wow fadeInUp" data-wow-delay="0.9s">
								<div className="text-center">
									<div className="text fw-700">3</div>
									<div className="text fw-700">Done!</div>
									<div className="text">
										The widget is running <br /> on your website
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default HowItWorks;
